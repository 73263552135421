:root {
    --blue: rgb(16, 41, 76);
    --dark-blue: #0d1623;
    --light-blue2: #007AFF;
    --light-blue: #44A2F4;
    --light-grey: #dddddd;
    --lighter-grey: rgb(238, 238, 238);
    --grey: #777777;
    --mid-grey: #aaaaaa;
    --mid-grey2: rgb(68, 68, 68);
    --dark-grey: #212121;
  }


.contextMenu {
    position: absolute;
    z-index: 3;
    box-shadow: 0 12px 20px 0px rgba(0, 0, 0, 0.3); 
    cursor: pointer;
    opacity: 0;
    z-index: -1;
    width: 150px;
    display: flex;
    background: none;
    flex-direction: column;
    font-size: 14px;
    border-radius: 6px;
    padding: 6px;
    border: 1px solid var(--light-grey);
    background: rgba(238, 238, 238, 0.8);
    backdrop-filter: blur(5px);
}


.contextBtn {
    padding: 4px 8px;
    transition: all 0.1s ease;
    border-radius: 4px;
    margin: 2px 0;
   
}

.contextBtn:hover {
    background: var(--light-blue2);
    color: white;
}
.break {
    border-top: 0.5px solid var(--grey);
    background: var(--lighter-grey);
    margin: 5px;
    /* padding: 10px 0; */
}

.dark .contextMenu {
    background: rgba(68, 68, 68, 0.8);
    border: 1px solid var(--mid-grey2);
}