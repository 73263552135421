:root {
    --blue: rgb(16, 41, 76);
    --dark-blue: #0d1623;
    --light-blue2: #007AFF;
    --light-blue: #44A2F4;
    --light-grey: #dddddd;
    --lighter-grey: #eeeeee;
    --grey: #777777;
    --mid-grey: #aaaaaa;
    --mid-grey2: #444444;
    --dark-grey: #212121;
    --white-20: rgba(255,255,255,0.2);
    --black-20: rgba(0, 0, 0, 0.2);
    --yellow: rgb(255, 200, 0);
    --gold: #DCC080;
    --red: #DC1228;
    --brookfieldBlue: rgb(27,114,232);
    --brookfieldBlue-10: rgba(27,114,232,0.1);
    --brookfieldBlue-50: rgba(27,114,232,0.5);
    --green: #34A853;
  }

  ::-webkit-scrollbar {
	display: none;
}
::selection {
    /* color: red; */
    color: var(--dark-grey);
    background: var(--gold);
}

body {
    margin: 0;
    padding: 0;
    font-family: "Inter", sans-serif;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-size: 15px;
}

body .dark {
    color: rgb(212,212,212);

}

.app {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    height: 100vh;
    overflow-y: scroll;
    transition: all 0.3s ease;
}

.app.dark {
    background: #212121;
}

.app.light {
    background: var(--lighter-grey);
}

figure {
    margin: 0;
    padding: 0;
}

.ui {
    cursor: pointer;
}

h1 {
    font-size: 20px;
    cursor: default;
}

h2 {
    font-size: 18px;
    margin: 0 0 15px 0;
    line-height: 100%;
}

h3 {
    font-size: 14px;
    line-height: 100%;
}

p {
    font-size: 15px;
    line-height: 140%;
}

.small {
    font-size: 14px;
}

*::-webkit-scrollbar {
    background: var(--black-20);
    width: 10px;
  }

a, a:visited, .light a.on, .light a.on:visited {
    color: #fff;
    text-decoration: none;
}

.light a, .light a:visited, body .light {
    color: #212121
}

nav {
    padding: 0 0 0 0;
    margin-left: -12px;
}

nav a#nav0.on {
  background: none;
  color: #212121;
}

.dark nav a#nav0.on {
    color:white;
}

nav a {
    margin: 0 5px 0 0;
    padding: 2px 12px;
    border-radius: 20px;
    transition: all 0.3s ease;
    font-size: 14px;
}

nav a:hover {
    background: var(--white-20);
}


nav a.on, nav a.on:hover {
    background: var(--brookfieldBlue);
    /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4); */
}



header {
    border-bottom: 0.5px solid var(--white-20);
    /* margin-bottom: 30px; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* background: var(--blue); */
    z-index: 4;
    background: var(--dark-grey);
}

.light header {
    border-bottom: 0.5px solid var(--black-20);
    background: white;
}

header .flexRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
}


/* Tools */

#tools {
    width: 16%;
    float: left;
    padding: 50px 10px 0 10px;
    height: 100vh;
    border-right: 0.5px solid var(--white-20);
    /* display: flex;
    justify-content: flex-start; */
}

.light #tools  {
    border-right: 0.5px solid var(--black-20); 
}

.userTools {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 3;
}

.btn {
    cursor: pointer;
}

.btn:focus, .btn:active {
    /* background: rgba(255,255,255,0.2) */
}

.btn.round {
    border: 0.5px solid var(--white-20);
    border-radius: 40px;
    width: 32px;
    height: 32px; 
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white-20);

}


/* Ckeditor */

.ck-editor__editable {min-height: 150px;}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar, .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.dark .ck.ck-editor__main>.ck-editor__editable {
    background-color: var(--mid-grey2);
}

.dark .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: var(--white-20);
}




/* User Form */

.modalForm {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0px;
    right: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--dark-grey);
    height: 0;
    overflow: hidden;
    opacity: 0;
    background: rgba(0,0,0,0.2);
    width: 100vw;
    z-index: 3;
    /* z-index: -1; */
    
}


/* .light .userForm {
    background: rgba(255, 255, 255, 0.3);
} */

.modal {
    background: var(--mid-grey2);
    padding: 30px 30px 30px 30px;
    border-radius: 10px;
    box-shadow: 0 12px 20px 0px rgba(0, 0, 0, 0.3); 
    min-width: 300px; 
}

.modal {
    padding: 0;
}

.formBody {
   padding: 30px;
    display: grid;
    grid-template-columns: 1fr;

}

.modal h2 {
    padding-bottom: 20px;
    border-bottom: 0.2px solid var(--black-20);
}

.warning {
    padding: 40px;
}

.warning h2 {
    border: none;
}

.warning p {
    padding: 0 0 0 0;
    margin: 0;

}


#addProjectForm .modal h2 {
    padding-bottom: 10px;
    border-bottom: none;
}

#addProjectForm .inputField {
    margin-top: 5px;
    float: left;
}

.modalForm2 .modal{
    width: 100%;
    max-width: 500px;
}


.modalForm2 .modal h2 {
    padding-bottom: 10px;
    margin-bottom: 0;
    border: none;
}


.modalForm2 .formBody {
    gap: 20px;
}

.modalForm2 .dropDownField {
    margin: 0;
}

.modalForm2 .dropDownChoices {
    left: 0;
}

.modalForm2 input[type="text"] {
    margin: 0;
    width: calc(100% - 40px);
    padding: 10px 20px;
    border-radius: 10px;
}

.modalForm2 .buttonRow, .modalForm2 .modal .buttonRow {
    margin: 0;
    gap: 20px;
  
}

.modalForm2 .dropDownChoice, .modalForm2 .dropDownChoice.btn {
    text-align: left;
}

.modalForm2 .modal .btn.dateField {
    text-align: left;
    justify-content: flex-start;
    flex-direction:row;
}


.light .modal {
    background: #fff;
}

.modal input[type="text"] {
    max-width: calc(100% - 5px);
}

.modal input[type="text"]#taskName {
    min-width: 400px;
}

.modal .button {
    border-radius: 40px;
    padding: 10px 15px;
    margin: 0;
    text-align: center;
    border: 1px solid var(--white-20);
    
}

.light .modal .button {
    border: 1px solid var(--mid-grey);
}



.modal .buttonRow {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.teamButtonRow.buttonRow {
    flex-direction: column;
}


.projectPage textarea {
    min-height: 100px;
}

input[type="text"], input[type="password"],  input[type="password"], textarea {
    padding: 0;
    -webkit-appearance: none;
    background: none;
    font-family: "Inter", sans-serif;
    color: white;
    font-size: 14px;
    border: 1px solid var(--white-20);
    border-radius: 5px;
    padding: 5px;
    position: relative;
    margin: -5px 0 -5px -5px;
    background: var(--white-20);
    max-width: 80%;
    width: 100%;
}

.dark input[type="text"] {
   /* border: 1px solid var(--mid-grey); */
   background: var(--black-20)
}


input.submitBtn {
    width: 100%;
    padding: 0;
    -webkit-appearance: none;
    background: none;
    font-family: "Inter", sans-serif;
    color: white;
    font-size: 15px;
    border: 1px solid var(--white-20);
    border-radius: 5px;
    padding: 5px;
    position: relative;
    background: var(--brookfieldBlue);
}


.light input[type="text"], .light input[type="password"], .light textarea {
    background: rgba(0,0,0,0.1);
    border: 1px solid var(--black-20);
    color: var(--dark-grey);
}

textarea, .light textarea {
    max-width: 100%;
}

input[type="text" i]:focus, textarea:focus {
    outline: none;
    caret-color: var(--red);
}

.light textarea:focus {
    caret-color: var(--red);
}


input[type="text"]#name {
    width: 80%;
}

.placeholder {
    color:rgba(255, 255, 255, 0.4)
}

.light .placeholder, .light input::placeholder {
    color: var(--black-20)
}

.smCancel {
    position: relative;
    margin-left: -22px;
    top: 2px;
}

.smCancel.textarea {
    left: 0;
    top: 0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.smCancel svg {
    width: 16px;
    height: auto;
    overflow: visible;
}

textarea {
    padding: 5px;
    width: 100%;
    max-width: 100%;
    resize: vertical;
}


#content {
    position: relative;
    padding-top: 50px;
}

#showSettings {
    /* width: 50px;
    display: block; */
    display: grid;
    grid-template-columns: 1fr 20px;
    column-gap: 20px;
}

.userBtns {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    position: fixed;
    top: 50px;
    right: 10px;
    border: 0.5px solid var(--white-20);
    background: #212121;
}

.light .userBtns {
    border: 0.5px solid var(--black-20);
    background: white;
}

.userBtn {
    cursor: pointer;
    text-transform:capitalize;
    margin: auto;
}

.username {
    /* opacity:  */
}

.dark .userBtn path {
    stroke: white;
}

.dark .userBtn .fill path {
    fill: white;
}

.userBtn svg {
    height: 20px;
    width: auto;
}

.column {
    width: 96%;
    padding: 0 2%;
}

.buttons {
    float: left;
    width: 100%;
    margin: 20px 0 0 0;
}

.button {
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #fff;
    width: auto;
    float: left;
    padding: 10px;
    transition: all 0.1s ease;
    margin: 0 20px 0 0;
}


.rightColumn .button {
    margin: 0 20px 20px 0;
}

.rightColumn .projectField {
    margin-bottom: 10px;
}

.light .button {
    border: 1px solid #212121;
}

.button.on, .light .button.on, .teamBtn.on .button {
    background: var(--light-blue2);
    border: 1px solid var(--light-blue);
    color: #fff;
}

.button.loading {
    background: rgba(255,255,255,0.5);
}



.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255,255,255,0.85);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px);
}

.dark .loader {
    background: rgba(33,33,33,0.85);
}

.loader svg {
    width: 300px;
    height: 300px;
}

.loader circle {
    fill: var(--brookfieldBlue)
}

.itemHeader {
    display: none;
    grid-template-columns: 100px repeat(5, 1fr);
    /* grid-template-rows: 80px auto 80px;  */
    column-gap: 20px;
    row-gap: 20px;
    transition: all 0.3s ease;
    padding: 20px 20px 0 20px;
    height: 10px;
    justify-items: start;
    /* align-items: center; */
    color: var(--light-grey);
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
}



.heading {
    color: var(--light-grey);
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
}

.list .itemHeader, .itemHeader.ticketHeader {
    display: grid;
    grid-template-columns:repeat(7, 1fr);
}

.itemHeader.ticketHeader {
    height: auto;
}

.light .itemHeader, .light .heading {
    color: var(--grey);
}

.mainArray {
    padding-top: 20px;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.flex.siteFilters {
    justify-content: flex-start;
}

.siteFilter .icon {
    width: 32px;
    height: 32px;
    margin: 0;
}

.siteFilter .icon svg {
    width: 100%;
    height: 100%;
}

.siteFilter .icon path.background {
    fill: var(--brookfieldBlue);
}

.siteFilter .icon path.art {
    fill: white;
}

.projectIcon path.background {
    fill: var(--mid-grey);
}

path.art {
    fill: white;
}

.siteFilter.btn {
    cursor: pointer;
    padding: 4px 12px 4px 8px;
    margin: 0 0 0 0;
    display: flex;
    align-items: center;
    border-radius: 40px;
    color: white;
    background: rgba(255,255,255,0.1);
    transition: all 0.3s ease;
    min-width: 80px;
    justify-content: space-between;
    border: 1px solid transparent;
    min-height: 28px;
}

.siteFilter.btn.on {
    background: var(--brookfieldBlue);
    color: #fff;
}

.light .siteFilter.btn {
    color: var(--brookfieldBlue);
    background: var(--brookfieldBlue-10);
}

.groupHeading {
    color: var(--grey);
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    /* max-width: 0%; */
}

.groupHeading h2 {
    margin: 0;
}

.groupHeading .icon {
    height: 22px;
    width: 22px;
}

.clearBtn {
    height: 24px;
    width: 24px;
}

.clearBtn path {
    fill: var(--grey);
}


.statSm {
    font-weight: 700;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}


.assets .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px 0;
}

.bar {
    border-left: 1px solid var(--light-grey);
    height: 28px;
    width: 1px;
}

.siteFilter input[type="text"].search {
    background: none;
    border: none;
    padding: 10.5px;
    caret-color: var(--gold);
    min-width: 300px;
    color: white;
}

.siteFilter.btn:has(input[type="text"].search:focus) {
    border: 1px solid white;
}

.siteFilter input[type="text"].search::placeholder {
    color: var(--light-grey);
}

.light .siteFilter input[type="text"].search {
    caret-color: var(--brookfieldBlue);
    color: var(--brookfieldBlue)
}


.light .siteFilter.btn:has(input[type="text"].search:focus) {
    border: 1px solid var(--brookfieldBlue);
}

.light .siteFilter input[type="text"].search::placeholder {
    color: var(--brookfieldBlue-50);
}



.grid .mainArray {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 0.3fr));
    /* grid-template-rows: 80px auto 80px;  */
    column-gap: 36px;
    row-gap: 36px;
    transition: all 0.3s ease;
    width: 100%;
    transition: all 0.3s ease;
}

.grid .mainArray.projectsArray {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.grid .mainArray.projectsArray.arrayLength_1, .grid .mainArray.projectsArray.arrayLength_2, .grid .mainArray.projectsArray.arrayLength_3, .grid .mainArray.projectsArray.arrayLength_4 {
    grid-template-columns: repeat(auto-fit, 230px);
}

.list .mainArray, .grid .mainArray.ticketsArray {
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
}



/* .assets .mainArray {
    width: 68vw;
    left: 28vw;
    position: relative;
} */



.grid .siteBtn {
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    transition: all 0.3s ease;
}

.grid .projectsArray .siteBtn {
    aspect-ratio: 1.05;
}

.itemGroup {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    gap: 4px;
}

.itemHeading {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
}

.groupBtn .icon{
    width: 24px;
    height: auto;
    margin-top: 5px;
}

.groupBtn .icon path {
    fill: var(--light-grey);
    /* opacity: 0.5; */
}

.list .siteBtn, .siteBtn.ticket {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    border-bottom: 1px solid var(--white-20);
    transition: all 0.3s ease;
}
.siteBtn.ticket {
    grid-template-columns: repeat(7, 1fr);
    border-bottom: 1px solid var(--black-20);
    cursor: pointer;
}

.dark .siteBtn.ticket {
    border-bottom: 1px solid var(--white-20);
}

.siteBtn.status2 img {
    filter: grayscale(100%);
}

.siteBtn figure.thumb {
    display: flex;
    align-items: center;
    justify-content: center;
}

.siteBtn figure.thumb svg{
    width: 80px;
    height: 80px;
    opacity: 0.3;
}

.siteBtn figure.thumb path{
    fill: white;
}

.light .siteBtn figure.thumb path{
    fill: black;
}

.light .list .siteBtn {
    border-bottom: 1px solid var(--black-20);
}

/* .siteBtn:hover {
    background: var(--white-20);
}

.light .siteBtn:hover {
    background: var(--black-20);
} */

.list .projectsArray .siteBtn {
    grid-template-columns: 120px 20px repeat(3, 1fr);
    align-items: center;
}
/* 
.grid .projectsArray .siteBtn {
    display: grid;
    grid-template-rows: 80% 20%;
} */

.projectsArray .siteBtn .thumb {
    aspect-ratio: 1/1;
    padding: 0;
    margin: 0;
    min-width: auto;
    min-height: auto;
    /* max-height: 240px; */
    opacity: 1;
    border: none;
    box-shadow: 0 0px 3px rgba(0,0,0,0.3);
}

.light .projectsArray .siteBtn .thumb {
    border: none;
}

.projectsArray .statusIcon {
    /* box-shadow: 0 2px 4px rgba(0,0,0,0.5); */
 
}

.siteBtn .blockBottomRow { 
    margin-top: -220px;
    padding: 10px;
    width: calc(100% - 20px);
    z-index: 3;
    height: 200px;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0.2) 100%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.list .siteBtn .blockBottomRow {
    background: none;
    width: 30px;
    height: 30px;
    margin: 0;
    padding: 0;
}



.siteBtn .blockBottomRow path.background {
    fill: white;
}

.list .siteBtn .blockBottomRow path.background {
    fill: var(--mid-grey)
}

.projectBtnText {
    display: flex;
    flex-direction: column;
    padding: 15px 0 0 0;
}

.list .sitesArray .siteBtn {
    grid-template-columns: 280px repeat(5, 1fr);
}



.list .item {
    padding: 0 0 0 20px;
}

.itemText {
    margin: 10px 0 0 0;
    display: flex;
    flex-direction: column;
}

.siteBtn .thumb {
    border-radius: 10px;
    border: 1px solid var(--white-20);
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.05);
    object-fit: cover;
    margin: 0 0 20px 0;
    min-width: 280px;
    min-height: 160px;
}

.siteBtn figure.thumb {
    background: rgba(100,100,100,0.2);
}

.light .siteBtn figure.thumb {
    background: rgba(0,0,0,0.02);
}

.siteBtn .client {
    color: var(--grey);
    margin: 5px 0;
}

.grid .siteBtn .item {
    padding: 0 0 2px 5px;
    word-wrap: break-word;
    font-size: 13px;
    width: calc(100% - 5px);
}


.name {
    font-weight: 700;
    color: var(--gold);
}

.light .name {
    color: var(--grey)
}

.list .thumb {
    width: 100px;
    height: 70px;
    margin: 0;
}

.light .siteBtn .thumb {
    border: 1px solid var(--black-20);
}

.siteBtn h3 {
   text-align: left;
   margin: auto 0;
}

.list h3 {
    /* padding-left: 20px; */
}

.filters {
    display: grid;
    gap: 20px;
    align-items: center;
    grid-template-columns: repeat(5, 1fr);
    justify-content: space-between;
}
/* 
.grid .filters {
    margin-top: 40px;
} */

.assets {
    padding: 30px 0 30px 0;
}

.twoCol {
    display: grid;
    grid-template-columns: 25% 75%;
}

.leftNav {
    position: fixed;
    left: 0;
    height: 100vh;
    overflow-y: scroll;
    min-width: 30vw;
}

.heading .icon {
    opacity: 0.5;
}


.subAssetArray {
    margin: 60px 0 0 0;
    padding: 10px 0 0 0;
   
}

.subAssetArray .heading {
    padding-bottom: 20px;
}

.subAssetArray .itemHeader {
    /* border-top: 1px solid var(--white-20); */
   
}

.light .subAssetArray .itemHeader {
    /* border-top: 1px solid var(--black-20); */
}

.subRow.assetRow.itemHeader {
    grid-template-columns: 25% 10% 10% 10%;
}

.subRow.assetRow {
    grid-template-columns: 25% 10% 10% 10%;
    padding: 10px 0;
}


/* Sub Project */

.subHeading {
    display: flex;
    justify-items: flex-start;
    gap: 20px;
    align-items: center;
}

.subHeading h2 {
    margin: 0;
    padding: 0;
    font-size: 20px;
}

.backBtn {
    padding: 0 0 30px 0;
}

.backBtn.subProjectBack {
    padding: 0 0 15px 0;
}

.backBtn svg {
    height: 14px;
}

.backBtn path {
    fill: var(--grey)
}

.light .backBtn path {
    fill: black;
}

.subProject {
   /* margin-left: 40px; */
   /* min-height: 80vh; */
}

.subProjectIcons {
    display: flex;
    /* justify-content: space-between; */
    gap: 30px;
    margin: 10px 0 0 0;
}

.subProjectIcons .statusIcon {
    /* border-right: 1px solid var(--light-grey);
    padding-right: 20px; */
}

.dateFilterPos {
    position: relative;
    left: -104px;
    top: -18px;
}


.dateFilter {
    /* left: 10vw; */
    min-width: 400px;
    position: absolute;
 
}

.dateFields {
    display: flex;
    gap: 20px;
    /* margin: 20px 0 40px 0; */
}

.dateFields.hasEndDate{
    gap: 10px;
}

.dateField {
    display: flex;
    gap: 20px;
    align-items: center;
    color: var(--grey);
    font-size: 14px;
}

.endTo {
    padding-right: 5px;
}

.dateFields .heading {

}

.deleteBtn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 60px;
    background: var(--black-20);
    width: 42px;
    height: 40px;
    padding-bottom: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* display: none; */
    /* box-shadow: 0 1px 10px rgba(0,0,0,0.3); */
    /* backdrop-filter: blur(10px); */
}

.deleteBtn.deleteSubProject {
    right: 20px;
    display: none;
}

.light .deleteBtn {
    background: var(--lighter-grey);
}

.tasks {
    margin: 40px 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-top: 0.5px solid var(--white-20);
  
}

.light .tasks {
    border-top: 0.5px solid var(--black-20);
}

.tasks h3 {
    font-size: 18px;
}

.headingRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.taskFilters {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    align-items: center;
}

.filterBtn {
    border: 1px solid var(--mid-grey);
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 14px;
    height: 18px;
    display: flex;
    align-items: center;
    color: var(--grey)
}

.filterBtn .label {
    background: none;
    padding: 0;
    color: black;
}
.subProject .filterBtn  img.labelIcon, .subProject .filterBtn svg {
    width: 16px;
    height: auto;
}

.taskGrid {
    display: grid;
    gap: 0px;
    grid-template-columns: 1fr;
}

.taskRowGrid {
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    grid-template-columns: 16px 1fr;
  
}

/* .task.on .taskRowGrid {
    row-gap: 10px;
} */
.taskDetails .taskId p.small{
    /* color: black; */
}

.taskDetails {
    display: flex;
    gap: 15px;
    margin-left: 30px;
    justify-content: flex-start;
    align-items: center;

}
.taskDetails .fieldCta {
    width: auto;
    white-space: nowrap;
    /* display: block; */
}

.taskDetails p.small {
    white-space: nowrap;
    /* font-size: 13px; */
    margin: 0;
    line-height: 100%;
    color: var(--mid-grey);
}

.bugForm input[type="text"] {
    margin: 0;
}
.bugForm .formBody {
    gap: 10px;
}


.checkbox {
    border: 1px solid #A9AAAB;
    width: 16px;
    height: 16px;
    display: block;
    transition: all 0.2s ease;
    text-align: center;
    cursor: pointer;
    border-radius: 20px;
    opacity: 1;
}

.checkbox.noBorder {
    border: 1px solid transparent;
}


.checkmark {
    opacity: 0;
    transition: opacity 0.2s ease;
    width: 16px;
    height: 16px;
    /* pointer-events: none; */
}

.checkbox.checked {
    background: var(--light-blue2);
   border: 1px solid var(--light-blue2);
}

.checkbox.checked .checkmark {
    opacity: 1;
    transition: opacity 0.2s ease;
}


.taskGrid ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.taskGrid ul ul  {
    gap: 0px;
}


.dragLine {
    position: relative;
    border-top: 2px solid var(--light-blue2);
    display: flex;
    justify-content: flex-start;
    margin-left: 35px;
    opacity: 0;
    /*
    transition: all 0.2s ease; */
    /* padding-bottom: 20px; */
   
}

/* .dragLine.on {
    opacity: 1;
} */


.isDragging .dragLine {
    opacity: 1;
}

/* .isDragging .checkbox {
    opacity: 0;
} */

.dragHandle {
    display: block;
    width: 20px;
    height: 20px;
    /* border: 1px solid red; */
    /* cursor:grab; */
    position: relative;
    left: -30px; 
}

.drop {
    /* background: red; */
}


.task {
    display: grid;
    grid-template-columns: 10px 1fr;
    /* grid-template-rows: 2px 1fr; */
    row-gap: 0px;
    /* border-bottom: 0.5px solid var(--white-20); */
    padding: 0;
    justify-content: space-between;
    max-width: 61vw;
}

.taskRowGrid ul {
    width: 100%;
    float: left;
}


.taskRowGrid li {
   display: grid;
   grid-template-columns: 1fr;
}

.taskRow {
    padding: 10px 0;
}

.subtask.task {
    margin-left: 30px;
    grid-template-columns: 1fr;
    min-width: 55vw;
    max-width: 61vw;
    /* border-top: 1px solid red; */
}

.light .task {
    /* border-bottom: 0.5px solid var(--black-20); */
}

.task .text {
    margin: 0px 0;
    font-size: 14px;
    line-height: 140%;
}

.unRead {
    width: 8px;
    height: 8px;
    border-radius: 20px;
    background: var(--light-blue2);
    display: block;
    position: absolute;
    left: 18px;
    margin-top: 15px;
}

.task h4 {
    padding: 0;
    margin: 0;
    min-height: 18px;
    /* line-height: 100%; */
    font-weight: 700;
    font-size: 15px;
    margin-block-start: 0;
    margin-block-end: 0;
    display: flex;
    align-items: center;
}

.task input[type="text"] {
    width: 100%;
    max-width: 100%;
    background: none;
    padding: 0;
    border: none;
    margin: 0;

}

 .task .taskHeading input[type="text"] {
    font-weight: 700;
    font-size: 15px;
    margin: 0;
    padding: 0;
    border: none;
    min-height: 18px;
    display: flex;
    align-items: center;

       
}

.taskHeading {
    width: 100%;
    float: left;
    margin-bottom: 0px;
    display: flex;
    gap: 10px;
    height: 16px;
}

.taskRowGrid .fieldValue {
    border: none;
}

.projectPage .task .taskRowGrid textarea {
    min-height: 60px;
}

.taskDescription {
    display: flex;
    width: calc(100% - 26px);
    flex-direction: column;
    grid-column: span 2;
    left: 26px;
    position: relative;
    transition: opacity 0.2s ease;
    padding-top: 0px;
    opacity: 0;
    height: 0;
    transition: opacity 0.2s ease;
    overflow: hidden;
}

.on .taskDescription {
    opacity: 1;
    height: auto;
    padding-top: 10px;
}

.taskDescription p {
    line-height: 100%;
    margin: 0;
    /* padding: 0; */
}

.taskDescription p.template strong {
    color: var(--grey);
  
}

.taskDescription .fieldCta {
    height: 0;
    opacity: 0;
}


.on .fieldCta.taskFieldCta, .on .fieldCta.subTaskFieldCta {
    height: auto;
    opacity: 1;
}


.bugTask p strong {
    color: var(--grey);
    font-weight: normal;
    line-height: 200%;
}

.on .subTask 

.task.subRowBtn {
    cursor: default;
    /* border: none; */
    background: none;
}

.light .task.subRowBtn {
    border: none;
    background: none;
}

.projectPage .task textarea {
    min-height: 20px;
}

.fieldCta {
    font-size: 14px;
    color: var(--mid-grey);
    cursor: pointer;
    align-items: center;
    display: flex;
}

.initials .fieldCta {
    display: flex;
    align-items: center;
}

/* .taskItem .smCancel {
    left: 98%;
    top: -18px;
} */

.text.empty {
    padding: 0;
    margin: 0;
}


/* Detail */

.projectHeading {
    width: 100%;
    background: var(--dark-grey);
    border-bottom: 0.5px solid var(--white-20);
    position: fixed;
    z-index: 2;
    height: 60px;
    display: flex;
    align-items: center;

}

.light .projectHeading {
    background: var(--lighter-grey);
    border-bottom: 0.5px solid var(--black-20);
}

.projectHeading .column {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.projectCreate {
    display: flex;
    flex-direction: column;
}

#addLabelForm .modal .buttonRow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.nextRow {
    padding-top: 20px;
}

.modal .buttonRow.nextRow {
    /* flex-direction: row;
    gap: 30px; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    padding: 0 0;
    margin-top: 20px;
    border-top: 1px solid var(--light-grey);
    width: 100%;
}

.modal .btn {
    text-align: center;

}

#addLabelForm .modal .button {
    display: grid;
    grid-template-columns: 1fr 30px;
    width: auto;
}

.buttonRow.nextRow .btn {
    padding: 25px 0;
}

.buttonRow.nextRow .btn.save {
    color: var(--light-blue2);
    font-weight: 700;
    border-left: 1px solid var(--light-grey)
}

.detail {
    padding: 2% 12px;
    min-height: 70vh;
}

.headingFlex {
    display: flex;
    gap: 20px;
}

.headingFlex input {
    min-width: 340px;
}

.projectPage.detail {
    padding: 0;
}


.detailColumns {
    display: grid;
    grid-template-columns: 0.62fr 0.28fr;
    column-gap: 80px;
}


.projectPage .detailColumns {
    padding-top: 64px;
}

.detailColumns.wide {
    /* grid-template-columns: 1fr;
    column-gap: 0px; */
}

.sideBarRightBtn path {
    fill: var(--light-grey);
}

.light .sideBarRightBtn path {
    fill: black;
}

.projectPage .leftColumn {
    padding: 20px 0px 80px 0;
    /* min-height: calc(100vh - 100px);
    overflow: visible;
        overflow-y: scroll; */
}

.buttonRow {
    margin-top: 20px;
    float: left;
}

.projectPage .rightColumn, #ticketDetail .rightColumn  {
    border-left: 0.5px solid var(--white-20);
    height: calc(100vh - 155px);
    /* padding-top: 40px; */
    padding: 40px 32px 0 32px;
    right: 0;
    width: calc(38vw - 64px);
    position: fixed;
    overflow-y: scroll;
}


.proposal .projectThumbnail {
    text-align: left;
}

.proposal .projectHeading {
    display: none;
}

.proposal .detailColumns {
    grid-template-columns: 1fr;
}

.proposal .detailTabBtns {
    display: none;
}

.proposal .detailTab {
    position: relative;
    top: auto;
    opacity: 1;
}

.proposal .detailTab .backBtn {
    display: none;
}

.projectPage.proposal .detailColumns {
    padding-top: 0;
}

.projectPage.proposal .leftColumn {
    display: none;
}

.projectPage.proposal .rightColumn {
    border-left: none;
    right: 0;
    width: 95vw;
    display: flex;
    gap: 40px;

    /* grid-template-columns: 1fr 1fr; */
    /* width: calc(80vw - 64px); */
    /* right: 50%; */
}

.projectPage.proposal .detailTabs {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.projectPage.proposal .detailTab {
    height: auto;
}

.proposal .projectCore {
    height: auto;
    width: 50vw;
}

#detailTab_req {
    display: none;
}


.light .projectPage .rightColumn, .light  #ticketDetail .rightColumn {
    border-left: 0.5px solid var(--black-20);
    /* background: var(--lighter-grey); */
}

.light .projectPage.proposal .rightColumn {
    border-left: none;
}

.projectThumbnailGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    margin: 0 0 30px 0;
}

.projectThumbnailGrid h2 {
    font-weight: 500;
    color: var(--dark-grey);
    line-height: 110%;
}

.dark .projectThumbnailGrid h2 {
    color: white;
}

.projectThumbnailGrid .projectFields {
    display: flex;
    flex-direction: column;
}

.projectThumbnail {
    width: 100%;
    height: auto;
    float: right;
    text-align: right;
}


/* 
.projectThumbnail img.thumb {
    width: 60%;
    height: auto;

} */

.projectThumbnail .thumb {
    width: 75%;
    height: 75%;
    border: 0.5px solid var(--grey);
    object-fit: cover;
    border-radius: 10px;
    aspect-ratio: 1/1;
    cursor: pointer;
}

.proposal .projectThumbnail .thumb {
    max-width: 200px;
}

.projectThumbnail figure {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    flex-direction: column;
    color: var(--mid-grey)
}

#projectImageFile {
    width: 100%;
    z-index: 0;
    opacity: 0;
    height: 0;
    /* position: relative; */
    /* max-width: 100px; */
}

.detailColumns h1 {
    /* margin: 0 0 40px 0; */
    margin: 0;
    padding: 0;
    line-height: 100%;
  
}



.detail .heading {
    display: flex;
    justify-items: flex-start;
    gap: 5px;
    cursor: default;
}

.detail .heading.btn {
    cursor: pointer;
}

.heading svg {
    width: 24px;
    height: auto;
    overflow: visible;
}

.heading svg path, .field .icon path {
    fill: white;
}

.light .heading svg path, .light .field .icon path {
    fill: var(--light-grey);
}

.iconSm {
    width: 30px;
    height: auto;
}

.assetFields {
    margin-top: 20px;
}

.projectFields {
    display: grid;
    grid-template-columns: 1fr 1fr; 
    gap: 15px;
}

.projectField {
    display: grid;
    grid-template-columns: 110px 1fr;
    align-items: center;
}

.filterField {
    display: grid;
}

/* .projectField .dateFilter {
    top: 12vh;
    right:14vw;
} */

/* .projectField  .dateFilter.endDateFilter {
    right:1vw;
} */

.projectField .dateFilterPos{
    left: 100px;
}
.projectField .dateFilterPos.endDateFilter{
    left: 0px;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: var(--brookfieldBlue);
}

.clearDate {
    float: left;
    margin: 5px 0 0 0;
    padding: 5px 10px;
    font-size: 14px;
    width: auto;
    background-color: #f0f0f0;
    border: 1px solid #aeaeae;
    box-shadow: 0 2px 8px rgba(0,0,0,0.3);
    border-radius: 0.3rem;
    z-index: 2;
    position: relative;
}

.field {
    display: flex;
    flex-direction: column; 
}


.fieldwIcon, .field a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.field .icon {
    width: 16px;
    height: 16px;
    margin-left: 4px;
}

.field .icon path {
    /* fill: var(--brookfieldBlue); */
}

.fieldValue {
    border: 1px solid transparent;
    position: relative;
    z-index: 1;
    cursor: text;
}

.fieldValue.btn {
    cursor: pointer;
}

.fieldValue span.on{
    /* padding: 5px 10px;
    border-radius: 5px;
    position: relative;
    top: 0px;
    left: -10px;
    background: var(--white-20); */
}



.dropDownField {
    opacity: 0;
    position: absolute;
    z-index: 4;
    margin: 30px 0 0 0;
    transform-origin: 0 center;
}

.taskFilters .dropDownField {
    margin: 40px 0 0 0;
}

.dropDownField.on {
    opacity: 1;
    height: auto;
    overflow: visible;
}

.dropDown {
    position: relative;
    float: left;
    width: calc(100% - 70px);
    height: 20px;
}

.dropDownField.taskStatus, .dropDownField.status {
    margin-top: 20px;
}

.dropDown.on {
    opacity: 1;
}

.dropDownChoices {
    border: 1px solid var(--white-20);
    position: absolute;
    padding: 3px 6px;
    border-radius: 5px;
    /* background: rgba(255,255,255,0.2); */
    border: 1px solid var(--light-grey);
    background: rgba(238, 238, 238, 0.8);
    backdrop-filter: blur(10px);
    box-shadow: 0 12px 20px 0px rgba(0, 0, 0, 0.3);  
    /* top: 30px; */
    left: -10px;
    width: 150px;
    height: 0;
    overflow: hidden;
    max-height: 300px;
    overflow-y: scroll;
}



.dark .dropDownChoices {
    background: #333333;
    border: 1px solid var(--dark-grey);
}

.dropDownField.taskStatus .dropDownChoices, .dropDownField.status .dropDownChoices {
    top: 15px;
}

.dropDownChoices.status {
    width: 40px;
}

.dropDownChoice {
    display: flex;
    flex-direction: column;
    padding: 4px 8px;
    margin: 4px 0;
    font-size: 14px;
    /* border-bottom: 1px solid var(--white-20); */
    border-radius: 5px;
    font-size: 13px;
}

.dropDownChoice.gridChoice {
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 15px;
    align-items: center;
}

.dropDownChoice.gridChoice.labelBtn {
    display: grid;
    grid-template-columns: 1fr 20px;
    gap: 15px;
    align-items: center;
}


.dropDownChoice:last-child { 
    border-bottom: none;
}

.dropDownChoice.on, .dropDownChoice.on:hover {
    background: var(--light-blue2);
    color: #fff;
}


.status .dropDownChoice.on, .dropDownChoice.on:hover, .statusWithName .dropDownChoice.on{
    background: var(--light-blue2);
    color: white;
}

.dropDownChoice:hover {
    background: var(--light-blue2);
    color: white;
}


.light .dropDownChoice:hover {
    background: var(--light-blue2);
    color: white;
}

.subRow.itemHeader {
    grid-template-columns: repeat(5, 1fr);
    column-gap: 0px;
    row-gap: 0px;
    padding-bottom: 40px;
}

.subProjectsArray {
    margin: 60px 0 0 0;
    padding: 10px 0 0 0;
    border-top: 1px solid var(--white-20);
}

.light .subProjectsArray {
    border-top: 1px solid var(--black-20);
}

.subProjectsGrid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.list .subProjectsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    transition: all 0.3s ease;
    gap: 10px;
}

.subRow {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 10px;
}

.subItem {

}

.subProjectRow .text a {
    text-decoration: underline;
    color: var(--brookfieldBlue)
}

.cover {
    /* position: fixed;
    top: 120px;
    left: 0;
    width: 44vw;
    height: 62vh; */
    /* width: calc(100vw - 20px);
    height: calc(100vh - 70px); */
    background: rgba(33,33,33,1);
    /* padding: 20px;
    z-index: 3; */
    /* backdrop-filter: blur(30px); */
}

.light .cover {
    background: var(--lighter-grey);
}

.status1 .subRow {
    /* background: #DC1228; */
    /* background: var(--light-blue); */
}

.text {
    margin: 20px 0 40px 0;
}

.text.detailText {
    opacity: 0;
    height: 0;
    margin: 0 0 20px 0;
    transition: all 0.3s ease;
    overflow: hidden;
    width: 100%;
}

.text.detailText::-webkit-scrollbar {
    display: inline;
    border-radius: 20px;
    width: 10px;
    height: 10px;
}


.text.detailText::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;

   
}

.text.detailText .text {
    padding-right: 20px;
}

.detailText.on {
    opacity: 1;
    height: auto;
    margin: 20px 0 40px 0;
    overflow-y: scroll;
    max-height: 38vh;
}

.note {
    margin: 30px 0 0 0;
} 

.documents, .team {
    margin: 40px 0 0 0;
}

.documents .subRow {
    padding: 10px 0;
}

.iconBtn {
    width: 20px;
    height: auto;
}

.projectGroups {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
} 

.projectGroup:nth-child(1), .light .projectGroup:nth-child(1) {
    border-top: none;
    margin-top: 0;
}

.projectGroup {
    border-top: 0.5px solid var(--grey);
    margin-top: 20px;
}

.light .projectGroup {
    border-top: 0.5px solid var(--light-grey);
}

.groupHeading {
    color: var(--brookfieldBlue);
    margin: 0 0 0 0;
}

.groupHeading .icon path {
    fill: var(--white-20);
}
.light .groupHeading .icon path {
    fill: var(--black-20);
}
.groupHeading h3{
    font-size: 20px;
}

.statusIconBtn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.statusIcon:hover .statusIconBtn {
    background: var(--white-20);
}

.light .statusIcon:hover .statusIconBtn{
    background: var(--black-20);
}

.subProjectsGrid .projectIcon, .subProjectsGrid .field, .subProjectsGrid .fieldValue  {
    pointer-events:none;
}

.subProjectsGrid .dropDownChoice {
    pointer-events: all;
}

.subRowBtn {
    cursor: pointer;
    border-radius: 10px;
    /* border: 1px solid var(--dark-grey); */
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    object-fit: cover;
    /* max-width: 30%; */
    background: #333333;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 15px 20px;
    aspect-ratio: 1.61/1;
    box-shadow: 0 0px 3px rgba(0,0,0,0.15);
}

.light .subRowBtn {
    background: rgba(255,255,255,0.5);
    /* border: 1px solid var(--light-grey); */
}

.list .subProjectsGrid .subRowBtn {
    border-radius: 0;
    height: auto;
    background: none;
    display: grid;
    aspect-ratio: auto;
    box-shadow: none;
   
    border: none;
    padding: 20px 0 30px 0;
    border-bottom: 0.5px solid var(--black-20);
}

.list .subRowBtn .btnInside {
    grid-template-columns: repeat(5, 1fr);
}

.list .subProjectsGrid .subRowBtn:nth-last-child() {
    border-bottom: none;
}



.btnInside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    /* word-break: break-all; */
}

.subRowBtnWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
}

.badge {
    position: absolute;
    background: var(--brookfieldBlue);
    color: white;
    font-weight: 500;
    border-radius: 20px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -5px -5px 0 0;
    font-size: 12px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.7);
    /* background: var(--lighter-grey); */
}

.badge.new {
    /* background: var(--red); */
}

/* .projectStats {
    height: 10px;
    width:100%;
    display: flex;
    gap: 10px;
    margin: 20px 0 0 0;

}

.projectStats .siteFilter.btn {
    height: 100%;
    width: auto;
    min-width: 60px;
    background: var(--lighter-grey);
    align-items: center;
} */

.subRowBtn.status3 {
    border: 1px solid #FFCC02;
}

.subRowBtn h4 {
    margin: 0 0 0px 0;
    display: flex;
    gap: 10px;
    align-items: center;
}
.subRowBtn p {
    margin: 20px 0 20px 0;
    font-size: 14px;
    word-wrap: break-word;
}

.subRowBtn .dropDownChoices {
    top: 20px;
    left: 180px;
}

.subProjectsGrid .subRowBtn .dropDownChoices {
    left: -10px;
}

.subRowBtn .subRowText {
    opacity: 0;
    height: 0;
    margin: 0;
    color: #aaa;
    font-size: 14px;
    transition: opacity 0.3s ease;
    overflow: hidden;
}

.subRowBtn.on .subRowText {
    margin: 10px 10px 30px 10px;
    opacity: 1;
    height: auto;
    /* overflow: visible; */
}

.subRowText .row {
    width: 100%;
    float: left;
}

.subRowText .heading {
    color: #fff;
    padding: 0 15px 0 0;
}

.initials {
    display: flex; gap: 5px; 
}

.teamInitial {
  border-radius: 60px;
  width: 32px;
  height: 32px;
  background-color: var(--grey);
  display: flex;
  align-items: center;
  justify-content: center;
}

.light .teamInitial {
    background-color: var(--light-grey);
}

.labels {
    display: flex; 
    gap: 10px; 
    white-space: nowrap;
    /* margin-left: 15px; */
}

.label {
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    border-radius: 20px;
    font-weight: 500;
    background: #dddddd;
    gap: 5px;
    max-height: 30px;
}

.dark .label {
    color: var(--mid-grey2);
}

.dark .filterBtn .label {
    color: var(--mid-grey);
}

.dark .dropDownChoice.labelBtn path {
    fill: white;
}

.dark .filterBtn .label path {
    fill: var(--mid-grey);
}

.taskHeading .label svg {
    padding: 3px;
}

.taskHeading .label {
    padding: 0;
    width:22px;
    height: 22px;
    position: relative;
    top: -6px;
}

.projectIcon {
  width: 24px;
  height: 24px;
}

.blocked {
    width: 22px;
    height: 22px;
    top: -4px;
    left: -4px;
    position: relative;
}


.statusIcon {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    /* text-transform: up; */
}

.list .subProjectsGrid .statusIcon {
    /* width:  */
    padding: 8px;
    /* background: white; */
    /* border: 0.5px solid var(--grey); */
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.3); */
    border-radius: 40px;
}

.list .subProjectsGrid .projectIcon {
    pointer-events: none;
}

.blockBottomRow {
    display: flex;
    margin: 0 0 0 0;
    justify-content: space-between;
    align-items: flex-end;
}


.teamFields {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px
}

.requirementGroupArray {
    margin: 40px 0 0 0;
    padding: 20px 0 0 0;
    border-top: 1px solid var(--white-20);
    float: left;
    width: 100%;
}

.light .requirementGroupArray {
    border-top: 1px solid var(--light-grey);
}

.requirementGroupArray .heading {
    padding-bottom: 20px;
}

.requirementGroupRow {
   padding: 10px 0 0 0;
   width: 100%;
   float: left;
}

.requirementGroupName {
    cursor: pointer;
}

.requirementGroup {
    width: 100%;
    float: left;
    padding: 0 0 10px 0;
}

.requirementGroup .description {
    width: 100%;
    float: left;
    padding: 10px 0 0 0;
    color: var(--light-grey);
    font-size: 13px;
}

.requirementRow {
    color: #aaa;
    font-size: 14px;
}

.requirementGroupSubRow {
    transition: opacity 0.3s ease;
    overflow: hidden;
    opacity: 0;
    height: 0;
    margin: 0;
    float: left;
    width: 100%;
}

.requirementGroupSubRow.on {
    overflow: visible;
    height: auto;
    opacity: 1;
    margin: 10px 0 0 0;
}

.requirementStatus {
    width: 100%;
    float: left;
    margin: 10px 0 10px 0;
}

.alert {
    width: 100vw;
    height: 100vh;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    backdrop-filter: blur(20px);
    top: 0;
    left: 0;
}

.alert .buttons {
    float: none;
    width: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.alert .button.on {
    background: rgba(27,114,232, 1);
    color: #fff;
    border: 1px solid rgba(27,114,232, 1);
}

.alert .button {
    width: 100px;
    float: none;
    margin: 0;
    text-align: center;
}

.alert h2 {
    font-size: 40px;
}

.serverResponse {
    background: rgba(0,0,0,0.8);
    float: left;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    color: #29F712;
    transition: all 0.3s ease;
}

.serverResponse .smCancel {
    left: -5px;
}

.screenshot {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 3px 14px rgba(0,0,0,0.25);
    margin-bottom: 30px;
}

figure.screenshot {
    min-height: 300px;
}

.reloadRow {
    display: flex;
    justify-content: flex-end;
}

.reloadRow .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.inputField {
    width: 100%;
}

.inputField.dropDownField {
    width: auto;
}

/* Transitions */

.main {
    /* opacity: 0; */
}


.main.pageItem-enter  {
    opacity: 0;
  }
  
  .main.pageItem-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease;
}
.main.pageItem-exit, .main.pageItem-enter-done {
    opacity: 1;
}

.cover.pageItem-enter  {
    opacity: 0;
  }
  
  .cover.pageItem-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease;
}
.cover.pageItem-exit, .cover.pageItem-enter-done {
    opacity: 1;
}


/* Reports */

.reports {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.reportDetail {
    position: relative;
    z-index: 1;
    padding: 20px calc(2% + 12px) 50px calc(2% + 12px);
    position: relative;
    z-index: 1;
    background: var(--dark-grey);
    transition: opacity 0.3s ease;
    min-height: 100vh;
    margin-top: 111px;
    color: #666;
}

.reportDetail h2 {
    color: #000;
    margin: 0;
}

.light .reportDetail {
    background: var(--lighter-grey);
}

.reportDetail.locations {
    transform: translateY(66.5vh);
    margin-top: 0;
}

.map-container {
    position: fixed;
    top: 100px;
    left: 0;
    width: 100vw;
    z-index: 0;
    height: 62vh;
}

.branding {
    position: fixed;
    top: 50.5px;
    left: 0;
    width: calc(96% - 24px);
    padding: 0 calc(2% + 12px);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: 0.5px solid #DEDEDE;
    background: white
}

.branding h1 {
    margin: 0;
    padding: 0;
}

nav.subNav {
    position: fixed;
    top: 111px;
    left: 0;
    width: calc(96% - 24px);
    padding: 0 calc(2% + 12px);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: 0.5px solid #DEDEDE;
}

.noBrand nav.subNav {
    top: 50px;
}

.light nav.subNav {
    background: white;
    border-bottom: 0.5px solid #DEDEDE;
}

nav.subNav ul {
    list-style: none;
    margin:0;
    padding: 0;
}
nav.subNav ul li {
    cursor: pointer;
    padding: 8px 15px;
    float: left;
    margin: 0 10px 0 0;
    display: flex;
    align-items: center;
    border: 1px solid var(--brookfieldBlue);
    border-radius: 40px;
    color: var(--brookfieldBlue);
    background:  var(--brookfieldBlue-10);
    transition: all 0.3s ease;
}

nav.subNav ul li.on {
    background: rgba(27,114,232, 1);
    color: #fff;
}
nav.subNav ul li.on path, nav.subNav ul li.on circle {
    fill: #fff;
}

nav.leftNav {
    border-right: 1px solid var(--white-20);
    height: 100vh;
    float: left;
    padding: 0 0 0 2vw;
    position: relative;
    z-index: 1;
    background: var(--dark-grey);
}

nav.leftNav ul {
    list-style: none;
    margin:0;
    padding: 0;
}
nav.leftNav ul li {
    cursor: pointer;
    padding: 10px 0 10px 0;
}

.reportArray {
    display: flex;
    flex-direction: column;
}

.reportHeading {
    padding: 10px 0 10px 0;
    border-top: 1px solid var(--white-20);
    border-bottom: 1px solid var(--white-20);
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(6, 1fr);
    font-weight: bold;
}

.reportHeading.engagement, .reportRow.engagement {
    grid-template-columns: 0.5fr 1fr 0.5fr 0.5fr 0.5fr;
}

.reportHeading.engagement.userReport, .reportRow.engagement.userReport {
    grid-template-columns: 0.5fr 0.5fr 0.5fr 1fr 0.5fr 0.5fr 0.5fr;
}


.light .reportHeading {
    /* background: var(--lighter-grey); */
    border-top: 0.5px solid var(--black-20);
    border-bottom: 0.5px solid var(--black-20);
}

.reportHeading span {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.chartHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.pagination {
    display: flex;
    align-items: center;
    gap: 10px;
}

.pageBtn {
    cursor: pointer;
}

.reportRow {
    padding: 10px 0 10px 0;
    border-bottom: 1px solid var(--white-20);
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(6, 1fr);
}

.light .reportRow {
    /* background: var(--lighter-grey); */
    /* border-top: 0.5px solid var(--black-20); */
    border-bottom: 0.5px solid var(--black-20);
}



.dateSelected {
    cursor: pointer;
    border: 1px solid var(--brookfieldBlue);
    color: white;
    background: var(--brookfieldBlue);
    border-radius: 5px;
    padding: 5px 10px;
    margin: -5px 0 -5px -5px;
    height: 20px;
}


.dateFilter .react-datepicker {
    box-shadow: 0 50px 100px -20px rgb(0 0 0 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
}

.userSelected input::placeholder{
    color: rgba(255,255,255,0.5)
}

.light .userSelected input::placeholder {
    color: rgba(0,0,0,0.3)
}

.light .userSelected input {
    background: #eeeeee;
}


.reportCharts {
    min-height: 400px;
    width: 100%;
    margin: 20px 0 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.chartGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 20px;
}

.grid3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 20px;
}

.chart {
    background: var(--light-grey);
    border: 0.5px solid var(--black-20);
    padding: 24px;
    border-radius: 5px;
    width: auto;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
}

.light .chart {
    background: white;
    border: 0.5px solid var(--black-20);
}

.reportCol {
    display: flex;
    height: 100%;
    align-items: center;
}

.chart.lg {
    max-height: 776px;
    overflow-y: scroll;
}

.chart.lg h2 {
    position: absolute;
}

.chart.lg .xAxis {
    position: fixed;
    top: 0;
}

.chart.sm {
    display: flex;
    flex-direction: column;

}

.chart.sm svg{
    overflow: visible;
}

.headingSm, .statRow {
    border-bottom: 0.5px solid #E6E6E6;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #9B9B9B;
}

.headingSm {
    font-size: 14px;
    padding-bottom: 5px;
    /* margin-bottom: 5px; */
}

.statRow { 
    padding: 10px 0;
    color: #666;
}

.statRow span {
    display: flex;
    height: 100%;
    align-items: center;
}

.statLg {
    font-size: 66px;
    font-weight: 700;
    margin-top: -20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--brookfieldBlue);
}

.userIcon {
    padding-right: 5px;
}

.icon {
    height: 16px;
    width: auto;
    margin: 0 5px 0 0;
}

.iconMd {
    height: 22px;
    width: auto;
}

.iconLg {
    height: 32px;
    width: auto;
}

.arrowIcon {
    width: 20px;
    height: auto;
    margin: 0 0 0 5px;
    transition: all 0.2s ease;
    opacity: 0;
    transform: scaleY(-100%);
}
.arrowIcon.on{
    opacity: 1;
}

.arrowIcon.des {
    transform: scaleY(100%);
}

.reports input[type="text"], .reports textarea {
    color: white;
    border: 1px solid var(--brookfieldBlue);
    color: var(--brookfieldBlue);
    background:  var(--brookfieldBlue-10);
    /* transition: all 0.3; */
}

.reports .light input[type="text"], .reports .light textarea {
    border: 1px solid var(--brookfieldBlue);
    color: var(--brookfieldBlue);
    background:  var(--brookfieldBlue-10);
}

.reports input[type="text" i]:focus, .reports textarea:focus {
    outline: none;
    caret-color: var(--gold);
}


.reports  .userSelected input::placeholder, .reports .light .userSelected input::placeholder {
    color: var(--brookfieldBlue-50);
}

.reports .userSelected.on input[type="text"] {
    border: 1px solid var(--brookfieldBlue);
    color: white;
    background: var(--brookfieldBlue);
}

.closeBtn {
    position: absolute;
    z-index: 3;
    cursor: pointer;
}

.closeBtn svg {
    position: relative;
    left: -22px;
    top: 3px;
    width: 14px;
    height: auto;
}

.closeBtn svg path, .closeBtn svg g {
    pointer-events: none;
}


.legend {
    padding-left: 5%;
    width: 90%;
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    flex-wrap: wrap;


}

.legendItem {
    width: auto;
    display: flex;
    justify-items: flex-start;
    align-content: center;
    height: 20px;
    text-transform:capitalize;
}

.legendItem svg {
    width: 14px;
    margin: 0 10px 0 0;
}

.legendItem span {
    margin-left: 5px;
    color: #bebebe;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 78px;
    height: 30px;
    /* scale: 1; */

  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid var(--brookfieldBlue);
    background-color: var(--brookfieldBlue-10);
    -webkit-transition: .3s;
    transition: .3s;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 8px;
    color: var(--brookfieldBlue-50);
  }

  .on .slider {
    justify-content: flex-start;
    color: #fff;
    padding-left: 8px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .3s;
    transition: .3s;
    border: 1px solid var(--brookfieldBlue);
  }
  
  .on .slider {
    background-color: var(--brookfieldBlue);
  }
  
  .switch:focus .slider {
    box-shadow: 0 0 1px var(--brookfieldBlue);
  }
  
  .on .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(46px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .ping {
    z-index: 10;
    display: none;
  }

  .ping svg {
    width: 40px;
    height: 40px;
    /* width: 100%;
      height: 100%; */
  }
  
  .ping circle {
    fill: var(--gold);
    stroke: #fff;
    /* stroke-width: 10px; */
  }
  
.pulse {
    animation: pulse 1.5s infinite;
    transform-origin: center center;
  }
  
  .pulse2 {
    animation: pulse 1.5s infinite 0.75s;
    transform-origin: center center;
    display: none;
  }
  
  .pulse3 {
    animation: pulse3 1.5s infinite 0.75s;
    transform-origin: center center;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
  
    100% {
      transform: scale(2.1);
      opacity: 0;
    }
  }
  
  @keyframes pulse3 {
    0% {
      stroke-width: 1;
      opacity: 1;
    }
  
    100% {
      stroke-width: 10;
      opacity: 0;
    }
  }


  #loginFormPage {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .loginForm {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    max-width: 400px;
    max-height: 400px;
    gap: 10px;
  }

  .loginForm input {
    width: 100%;
    margin: 0;
  }

  .loginForm input.submitBtn {
    width: 100px;
  }

  .teamList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

.teamMember {
    display: flex;
    gap: 10px;
    align-items: center;
}

.teamFields .teamInitial {
    font-size: 12px;
    border-radius: 60px;
    width: 28px;
    height: 28px;
}

.teamInitial.ownerInitial {
    background: var(--brookfieldBlue);
    color: var(--white);
}

.light .teamInitial.ownerInitial {
    background: var(--brookfieldBlue-50);
    color: var(--brookfieldBlue);
}

.teamBtn {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 30px;
    align-items: center;
}

.teamBtn.owner.on .button {
    /* background: var(--red); */
    background: var(--brookfieldBlue);
    color: white;
    font-weight: 500;
}

.light .teamBtn.owner.on .button {
    color: var(--brookfieldBlue);
    background: var(--brookfieldBlue-50);
}

.teamBtn.on .button {
    /* background: var(--red); */
    background-color: var(--light-grey);
    color: var(--grey);
    border: 0.5px solid var(--grey)
}


.star path {
    fill: var(--lighter-grey);
}

.star {
    opacity: 0;
    transition: all 0.3s ease;
    cursor: pointer;
    width: 20px;
    top: -3px;
    position: relative;
}

.teamBtn .star {
    pointer-events: none;
}

.teamBtn.on .star {
    pointer-events: all;
}

.star g {
    pointer-events: none;
}

.teamBtn.on .star:hover {
    opacity: 1;
    pointer-events: all;
}

.star.on {
    opacity: 1;
}

.star.on path {
    fill: var(--brookfieldBlue);
}

.light .star.on path {
    fill: var(--brookfieldBlue-50);
}

.heading svg.smallPlus {
    height: 14px;
}

.heading svg.smallPlus path {
    fill: var(--grey);
}

.light .heading svg.smallPlus path {
    fill: var(--grey);
}

#projectDocFile {
    padding: 0 0 25px 0;
}


.formDropDown {
    float: left;
    width: 100%;
    margin: 10px 0 5px 0;
    padding: 10px 0;
}


.docGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 30px 0 0 0;
    gap: 10px;
}



.fileObj .subItem{
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 5px;
}

.fileIcon {
    height: 40px;
    width: 40px;
    object-fit: contain;
  
}

#addProjectDocForm .inputFields {
    padding: 20px 0;
    float: left;
    width: 100%;
    max-width: 320px;
}

.sortBtn {
    position: relative;
    z-index: 3;
}


.detailTab {
    /* opacity: 0;
    z-index: -1;
    height: 0;
    overflow: hidden; */
}

.detailTab {
    position: fixed;
    /* top: 150px; */
    top: 100vh;
    height: calc(100vh - 155px);
    padding: 10px 0 0 0;
    width: calc(38vw - 64px);
    background: var(--dark-grey);
    z-index: 3;
    opacity: 0;
}

.light .detailTab {
    background: var(--lighter-grey);
}

.detailTabBtns {
    position: fixed;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.detailTabBtn {
    display: grid;
    align-items: center;
    grid-template-columns: 32px 1fr;
}

.detailTab .tabHeading {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 0 30px 0;
    margin: 0 0 30px 0;
    border-bottom: 0.5px solid var(--black-20);
}

.detailTab .backBtn {
    padding: 0;
    /* border: 1px solid var(--mid-grey); */
    border-radius: 5px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(270deg);
}

.detailTab .tabHeading h3 {
    margin: 0;
    padding: 0;
    font-size: 16px;
}

g.fill path {
    fill: var(--brookfieldBlue)
}

.detailTabBtn .icon {
    width: auto;
    height: 20px;
}

.deleteBtn g.fill path {
    fill: black;
}

.projectTitle {
    display: flex;
    gap: 15px;
    align-items: center;
}

.projectTitle .backBtn {
    padding: 0;
    display: none;
}

.text a {
    text-decoration: underline;
    color: var(--brookfieldBlue)
}

.text p{
    font-size: 14px;
}

nav.m {
    width: calc(100vw - 4vw);
    left: 0;
    position: fixed;
    bottom: 0;
    height: 12px;
    padding: 20px 2vw;
    margin: 0;
    background: var(--black-20);
    z-index: 5;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    backdrop-filter: blur(20px);
    border-top: 0.5px solid var(--dark-grey);
    display: none;
}

.light nav.m {
    background: rgba(255,255,255,0.75);
    backdrop-filter: blur(20px);
    border-top: 0.5px solid var(--light-grey);
    justify-content: space-between;
    align-items: center;
}

nav.m a, nav.m a:visited, .light nav.m a:visited {
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: var(--grey);
    padding: 5px 10px;
    /* font-weight: 400; */
}

.light nav.m a {
    color: var(--grey);
}

nav.m a.on, nav.m a.on:hover, nav.m a.on:visited {
    background: none;
    color: white;
    background: var(--brookfieldBlue);
}

.showSettings {
    display: flex;
    justify-content: center;
}

.labelBlockRow {
    float: left;
    width: 100%;
    padding: 10px 0 20px 0;
}

.button .labelIcon {
    padding: 2px
}

.button.on .labelIcon {
    background: white;
    padding: 2px
}

.labelIcon {
    border-radius: 30px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.labelIcon.bugIcon {
    width: 44px;
    padding: 0 4px;
    display: grid;
    grid-template-columns: 22px 12px;
    font-size: 12px;
    color: var(--mid-grey2)
}

.labelIcon img {
   width: 80%;
   height: auto;
}

.subProject img.labelIcon {
    height: 60%;
    width: auto;
    border-radius: 0;
}

/* User Reports */

.userProjects {
    display: grid;
    grid-template-columns: 1fr;
    /* grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); */
    gap: 30px;
}

.userProject {
    padding: 20px 0 0 0;
    border-top: 0.5px solid var(--white-20);
}

.light .userProject {
    border-top: 0.5px solid var(--black-20);
}

.userStats {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 5px;
    margin-top: 10px;
}

.userProject h2 {
    /* font-weight: 400; */
}

.userProject h2.btn {
    font-weight: 700;
}

.userProject .projectIcon {
    height: 20px;
    display: none;
}

.userProject1 {
    opacity: 0;
    height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: opacity 0.3s ease;
}

.userProject1.on {
    height: auto;
    opacity: 1;
    margin: 20px 0 0 0;
}

.userProject h3 {
    font-size: 14px;
    margin: 0 0 10px 0;

}

.userProject h4 {
    font-size: 14px;
    font-weight: normal;
}

.userProject ul {
    margin: 0 0 0 25px;
    padding: 0;
}

.userProject ul li {
    font-size: 14px;
}

.userProject ul li h4 {
    margin: 0;
}

/* Tickets */

.projectField .statusName {
    width: auto;
    float: left;
}

.statusName {
    border: 1px solid var(--mid-grey);
    padding: 5px 15px;
    border-radius: 20px;
}

/* .statusName.done {
    color: white;
    border: 1px solid var(--light-blue2);
    background: var(--light-blue2);
} */

.statusName.new {
    color: white;
    border: 1px solid var(--light-blue2);
    background: var(--light-blue2);
    /* color: var(--light-blue2); */

}

.statusName.in-progress {
    color: white;
    border: 1px solid var(--mid-grey);
    background: var(--mid-grey);
}


.statusName.approved {
    color: white;
    border: 1px solid var(--green);
    background: var(--green);
}

.statusName.returned {
    /* color: white; */
    border: 1px solid var(--yellow);
    background: var(--yellow);
}

.ticketForm {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
#ticketDetail .item {
    width: 100%;
}

#ticketDetail .flexItem {
    display: flex;
    gap: 10px;
}

#ticketDetail .leftColumn {
    padding-top: 30px;
}

.historyRows {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 0 30px 0;
}

.historyRow {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ticketRow {
    margin-top: 40px;
    float: left;
    width: 100%;
}

.ticketBtnRow {
    display: flex;
    gap: 20px;
    position: fixed;
    bottom: 0;
    height: 80px;
    align-items: center;
    background: var(--dark-grey);
    border-top: 1px solid var(--mid-grey2);
}

.light .ticketBtnRow {
    background: var(--lighter-grey);
    border-top: 1px solid var(--light-grey);
}

.adminBtns {
    justify-content: flex-start;
}


.ticketFilters .column {
    display: flex;
    gap: 0;
    justify-content: space-between;
    align-items: center;
    
}

.ticketArray {
    position: relative;
    padding-top: 80px;
    /* top: 80px;
    margin-top: 20px; */
}

#ticketDetail .rightColumn {
    max-height: calc(100vh - 170px);
}


.logDate {
    font-size: 13px;
    color: var(--grey);
    border-top: 1px solid var(--mid-grey2);
    padding: 10px 0 15px 0;
}

.light .logDate {
    border-top: 1px solid var(--light-grey);
}

.logNote h3 {
    font-size: 13px;
    color: var(--light-grey);
    margin-top: 5px;
}

.light .logNote h3, .light .logNote p, .light .logItem ul  {
    color: var(--mid-grey2);
}

.light .logDate {
    color: var(--mid-grey)
}

.logNote p{
    font-size: 13px;
    color: var(--light-grey);
    margin: 5px 0 5px 0;
}

.logItem ul {
    font-size: 13px;
    padding: 0 0 0 15px;
    margin-bottom: 10px;
    color: var(--light-grey)
}

.adminCta {
    color: var(--mid-grey)
}

.light .adminCta {
    color: var(--mid-grey2)
}


.light .ticketDetail  input[type="text"] {
    background: none;
    border: none;
}

.ticketDetail .formSearch {
    /* max-width: 400px; */
}

.ticketDetail .projectField.searchField {
    /* align-items: flex-start; */
    position: relative;
    z-index: 1;
}

.ticketFiles {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin: 60px 0 100px 0;
    border-top: 0.5px solid var(--black-20);
    padding: 20px 0 0 0;

}

.ticketFiles img, .ticketFiles video{
    width: 100%;
    height: auto;
}


/* Search */

.formSearch {
    background: #fff;
    border-radius: 10px;
    border: 1px solid var(--black-20);
    float: left;
    width: 100%;
    z-index: 4;
    background: rgba(0,0,0,0.1);
    /* box-shadow: 0 3px 10px rgba(0,0,0,0.2); */
    transition: all 0.2s ease;
  }

  .formSearch.hasResults {
    border-radius: 20px;
  }

  .modalForm2 .formSearch input[type="text"] {
    background: none;
    border: none;
}

.modalForm2 .modal .formSearch input[type="text"] {
    /* width: calc(100% - 40px) */
    width: calc(100% - 40px);

    
}

  .formSearch input{
    -webkit-appearance: none;
    padding: 10px 20px;
    font-size: 14px;
    border: none !important;
    width: calc(100% - 80px);
    background: none !important;
  }

  .siteFilter.formSearch input {
    
  }
  
  .light .siteFilter.formSearch.btn:has(input[type="text"]:focus) {
    border: 1px solid var(--brookfieldBlue);
    }

    .light .siteFilter.formSearch input[type="text"]::placeholder {
        color: var(--brookfieldBlue-50);
    }


  .search input:focus {
    outline: none;
  }
  
  .search input::selection {
    background: black;
    color: white;
  }
  
  .results {
    float: left;
    width: calc(100% - 40px);
    padding: 20px 20px 40px 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .results .result{
    font-size: 14px;
    font-weight: 600  
}

.noResults {
    position: absolute;
    top: calc(50vh - 18px);
    left: calc(50vw - 77px);
}

/* Home */
.homeRow {
    padding: 40px 0;
}
  
@media only screen and (max-width : 900px) { 
    body {
        font-size: 14px;
    }

    h1, .groupHeading h3 {
        font-size: 16px;
    }

    .column {
        width: 92%;
        padding: 0 4%;
    }

    .task {
        max-width: 100%;
    }

    .task .taskHeading input[type="text"] {
     
    }

    .app-header, .assets .filters {
        display: none;
    }
    #content, .assets {
        padding-top: 0;
    }
    .grid .mainArray.projectsArray, .grid .mainArray {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }
    
    .siteBtn .thumb {
        min-width: auto;
        min-height: auto;
        
    }

    nav.m, .light nav.m {
        display: flex;
    }

    .projectsArray .siteBtn .thumb {
        width: 100%;
        height: auto;
    }
    .userBtns {
        top: -65px;
        right: 0;
        /* background: var(--black-20); */
        backdrop-filter: blur(20px);
        border-bottom: none;
    }

    .light .userBtns {
        /* background: var(--white-20); */
        background-color: var(--lighter-grey);
        border-bottom: none;
    }

    .siteFilters {
        display: none;
    }

    .subProjectsGrid, .taskGrid {
        
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        gap: 15px;
    }

    .siteBtn figure.thumb svg {
        width: 15vw;
        height: 15vw;
    }

    .detailColumns {
        display: flex;
        column-gap: 0;
    }

    .projectPage .rightColumn, #ticketDetail .rightColumn {
        width: calc(100vw - 64px);
        /* left: 0; */
        transform: translateX(100%);
        background: var(--dark-grey);
        /* right: calc(-100vw - 64px); */
    }

    .light .projectPage .rightColumn, .light #ticketDetail .rightColumn { 
        background: var(--lighter-grey)
    }

    .projectPage .leftColumn {
        width: 100%;
    }

   .subRowBtn {
    padding: 15px 15px 10px 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    aspect-ratio: 1/1;
   }
   

   .projectField {
    display: grid;
    grid-template-columns: 90px 1fr;
    align-items: center;
    }

    .projectPage .rightColumn {
        width: calc(100vw - 64px);
    }
    
    .projectPage .rightColumn, #ticketDetail .rightColumn {
        border-left: 0.5px solid var(--white-20);
        height: calc(100vh - 140px);
        padding: 20px 15px 0 15px;
        width: calc(100vw - 30px);
    }

    .detailTab {
        height: calc(100vh - 140px);
        padding: 20px 15px 0 15px;
        width: calc(100vw - 30px);
        left: 0;
    }

    .projectThumbnailGrid {
        grid-template-columns: 61% 39%;
    }

    .projectField .dateFilterPos.endDateFilter, .projectField .dateFilterPos {
        left: 22vw;
        position: absolute;
        z-index: 4;
        top: 25vh;
    }

   .blockBottomRow .initials {
        display: none;
    }


}